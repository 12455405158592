import "./Privacy.css";
import useDocumentTitle from "../../useDocumentTitle";
import { Balancer } from "react-wrap-balancer";
import imp from "./imp.png";

const Privacy = () => {
    useDocumentTitle('BET IPTV | Privacy Policy');

    return(
    <body className="pcontainer">
        
        <div className="pbody">
            <h3 style={{color: "black",marginTop:"70px"}}>Privacy Policy For BET-IPTV</h3>
        <Balancer className="ptext">
      
<p>
We provide you with a mobile app that you can download to your mobile device. We reserve the right to change this privacy policy at any time with future version is available in the app. Please search the app regularly and find out about the applicable data protection regulations. 
Below we inform about the collection of personal data when using BET IPTV. Our privacy policy is structured as follows: 
</p>
<h4>
I.	Information about us as responsible <br></br>
II.	General data processing when using our mobile app <br></br>
III.	Rights of users and stakeholders <br></br>
IV.	Data processing information when using our mobile app <br></br>
</h4>

<h3>I. Information about us as responsible </h3>
BET IPTV is a media player brand owned by N.Ivanov  {/*BetStup UG*/}. All rights therefore belong to N.Ivanov{/*BetStup UG*/}. Responsible person according to Art. 4 Abs. 7 EU General Data Protection Regulation (DSGVO) is: 
<br></br><br></br>{/*BetStup UG*/} <br></br><br></br>
<img src={imp} width={120} alt=""/><br></br>
E-Mail: <a href="mailto:support@betiptv.com" style={{fontWeight:"600",fontSize:'small'}}>support@betiptv.com</a>  <br></br><br></br>
<h4>II.	General data processing when using our mobile app</h4> <br></br>
1.	Personal data is all data personally available to you, e.g. Name, address, e-mail address, user behavior. <br></br>
<br></br>2.	In principle, we process personal data of our users only to the extent necessary to provide a functional website and our content and services. The processing of personal data of our users takes pl 
the consent of the user. An exception applies to cases in which prior consent is not possible for reasons of fact and the processing of the data is permitted by law. <br></br>
<br></br>3.	Insofar as we obtain the consent of the data subject for processing of personal data, Art. 6 para. 1 lit. a DSGVO as legal basis. 
In the processing of personal data necessary for the performance of a contract of which the data subject is a party, Art. 6 para. 1 lit. b DSGVO as legal basis. This also applies to processing operati pre-contractual measures.
Insofar as the processing of personal data is required to fulfill a legal obligation that our company is subject to, Art. 6 para. 1 lit. c DSGVO as legal basis.
In the event that vital interests of the data subject or another natural person require the processing of personal data, Art. 6 para. 1 lit. d DSGVO as legal basis. 
If processing is necessary to safeguard the legitimate interests of our company or a third party, and if the interest, fundamental rights and freedoms of the data subject do not prevail over the firs 
f DSGVO as legal basis for processing. <br></br>
<br></br>4.	The personal data of the data subject processed with the use of our mobile app will be deleted or blocked as soon as the purpose of the storage is omitted. In addition, such storage may be provid 
national legislator in EU regulations, laws or other regulations to which the controller is subject (such as statutory retention requirements). A blocking or deletion of the data takes place even if a 
prescribed by the mentioned standards expires, unless there is a need for further storage of the data for a contract conclusion or a contract fulfillment. 
<br></br><br></br>5. If we rely on commissioned service providers for individual functions of our offer or would like to use your data for advertising purposes, we will inform you in detail below about the respective tr 
we also name the specified criteria for the storage duration. 
<br></br><br></br>6.	All information you submit to us will be stored on servers within the European Union. Unfortunately, the transmission of information over the Internet is not completely secure, which is why we c 
security of the data transmitted to our app via the Internet. However, we secure our app and other systems through technical and organizational measures against loss, destruction, access, modi of your data by unauthorized persons. In particular, your personal data is encrypted with us. We use the coding system SSL (Secure Socket Layer). 
<h4>III.	Rights of users and stakeholders </h4>

1. With regard to the data processing described in more detail below, you have the following rights with respect to the personal data relating to you: 
<br></br>
<h5>

- Right to information (Article 15 GDPR),<br></br>
- Right to rectification (Article 16 GDPR), <br></br>
- Right to cancellation (Art. 17 GDPR), <br></br>
- Right to restriction of processing (Art. 18 GDPR), - Right to information (Art. 19 GDPR), <br></br>
- Right to data portability (Article 20 GDPR). <br></br>
- Right to object to processing (Article 21 GDPR). <br></br>
</h5>
If you wish to claim any of your rights and / or request further information, please contact us via support@betiptv.com or the contact details provided. 
<br></br><br></br>2.	Without prejudice to any other administrative or judicial remedy, you have the right to lodge a complaint with a supervisory authority if you believe that the processing of your personal data in br Protection Acts. 
<br></br><br></br>3.	If you have any questions, comments or inquiries regarding the collection, processing and use of your personal data by us, please also contact us at the contact details provided. 
<h4>IV. Data processing information when using our mobile app </h4>
<br></br>1.	You can download the BET IPTV App directly to your mobile and TV device in the Play Store. When downloading the mobile app, the required information is transferred to the A username, e-mail address and customer number of your account, time of download and the individual device code. We have no influence on this data collection and are not responsible for it. We far as it is necessary for downloading the mobile app to your mobile device. Furthermore, you can download our app on our homepage in the "download" area. In this case, only the personal data r and the data required for payment processing will be collected and stored. 
<br></br><br></br>2.	BET IPTV can only be used with access to the Internet. Using the BET IPTV app, we collect the personal information described below to enable convenient, secure and stable use of the fe our mobile app, we collect the following data, which is technically necessary for us to offer you the functions of our mobile app and to ensure stability and security (legal basis is Art. 6 para. 1 p. 1 l 
- IP address, the Internet connection from which the use is made, - Date and time of the respective access,<br></br>
- Playlist of the user,<br></br>
- content of the request (concrete page), <br></br>
- Device AppID address <br></br> <br></br>
3. Email Contact <br></br>If you contact us via the provided e-mail address, the data you provide will be used to process your request. The specification of the data is necessary for processing and answering your inquiry - we can not answer your inquiry or at best only to a limited extent.
Legal basis for this processing is in the presence of a consent Art. 6 para. 1 lit. a GDPR.
The legal basis for the processing of the data transmitted in the course of sending an e-mail is Article 6 (1) lit. f DSGVO. 
The data will be deleted as soon as it is no longer necessary to achieve the purpose of its collection. For the personal data sent by e-mail, this is the case when the respective conversation with the conversation ends when it can be inferred from the circumstances that the matter in question has been finally clarified. 
<br></br><br></br>4.	Payment services <br></br>
We process your payment information for the purpose of payment processing. Depending on the payment method, we will forward your payment information to third parties (for example, in the payments to your credit card provider) 
We may collect data necessary to process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by Stripe. You may find their privacy notice link(s) here: https://stripe.com/de/privacy.
The user AppID will be given to Stripe in order to confirm the identity of the user and to give access to the service for the specific AppID.
The legal basis for data processing is Art. 6 para. 1 b) and f) DSGVO. The transfer is only made if it is actually necessary for the payment. Their rights are covered by section III. 1. 
<br></br><br></br>5.	 Deletion and storage duration <br></br>
The personal data of the data subject will be deleted or blocked as soon as the purpose of the storage is deleted. In addition, it may be stored if provided for by the European or national legislator other regulations to which the controller is subject. A blocking or deletion of the data takes place even if a storage period prescribed by the mentioned standards expires, unless there is a need fo data for a contract conclusion or a contract fulfillment.

        </Balancer>
        </div>
<div>
    <p>

‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
    </p>
</div>

    </body>
    );
};

export default Privacy;