import { useState } from "react";
import "./News.css";
import useDocumentTitle from "../../useDocumentTitle";

import { useTranslation } from "react-i18next";

const News = () => {
    const { t } = useTranslation(["news"]);
    useDocumentTitle('BET IPTV | ' + t("news:title"));

    const [newsItems] = useState([
    {
      id: 1,
      title: t("id1.title"), 
      content: t("id1.content"),
      date: '2023-05-15',
    },
    {
      id: 2,
      title: t("id2.title"), 
      content: t("id2.content"),
      date: '2023-05-17',
    },
    {
      id: 3,
      title: t("id4.title"),
      content: t("id4.content"),
      bulletPoints: [
        t("id4.bulletPoints.p1"),
      ],
      date: '2023-05-24',
    },
    {
      id: 4,
      title: t("id5.title"),
      content: t("id5.content"),
      bulletPoints: [
        t("id5.bulletPoints.p1"),
        t("id5.bulletPoints.p2"),
        t("id5.bulletPoints.p3"),
        t("id5.bulletPoints.p4"),
      ],
      date: '2023-06-20',
    },
    {
      id: 5,
      title: t("id6.title"),
      content: t("id6.content"),
      date: '2023-09-08',
    },
    {
      id: 6,
      title: t("id7.title"),
      content: t("id7.content"),
      date: '2023-11-10',
    },
    {
      id: 7,
      title: t("id8.title"),
      content: t("id8.content"),
      bulletPoints: [
        t("id8.bulletPoints.p1"),
        t("id8.bulletPoints.p2"),
        t("id8.bulletPoints.p3"),
        t("id8.bulletPoints.p4"),
      ],
      date: '2023-11-12',
    },
    {
      id: 8,
      title: t("id9.title"),
      content: t("id9.content"),
      bulletPoints: [
        t("id9.bulletPoints.p1"),
        t("id9.bulletPoints.p2"),
        t("id9.bulletPoints.p3"),
        t("id9.bulletPoints.p4"),
      ],
      date: '2024-03-26',
    },
    {
      id: 9,
      title: t("idP.title"),
      content: t("idP.content"),
      date: '2024-05-05',
    },
    {
      id: 9.1,
      title: t("id91.title"),
      content: t("id91.content"),
      bulletPoints: [
        t("id91.bulletPoints.p1"),
        t("id91.bulletPoints.p2"),
        t("id91.bulletPoints.p3")
      ],
      date: '2024-05-08',
    },
    {
      id: 10,
      title: t("id10.title"),
      content: t("id10.content"),
      bulletPoints: [
        t("id10.bulletPoints.p1"),
        t("id10.bulletPoints.p2"),
        t("id10.bulletPoints.p3"),
        t("id10.bulletPoints.p4"),
        t("id10.bulletPoints.p5")
      ],
      contentII: t("id10.contentII"),
      date: '2024-06-01',
    },
    {
      id: 11,
      title: t("id11.title"),
      content: t("id11.content"),
      date: '2024-06-04'
    }
]);



  // Sort newsItems array in descending order based on the date
  const sortedNewsItems = newsItems.sort((a, b) => new Date(b.date) - new Date(a.date));

return(
    <body className="ncontainer">
            <h2 style={{color: "black",marginTop:"50px"}}>{t("news:title")}</h2>
            <p style={{color:"black",fontSize:"22px"}}>{t("news:text1")}</p>
        <div className="news-container">
        {sortedNewsItems.map((item) => (
        <div key={item.id} className="news-item"> 
            <h2 className="news-title">{item.title}</h2>
            <p className="news-content">
            {item.content.split(' ').map((word, index) => {
              if (word === 'roadmap' || word === 'announce' || word === 'Release' 
              || word === 'Updates') {
                return <span key={index} style={{fontWeight:'bold'}}>{word} </span>;
              }

              else if (word === 'here.') {
                return <a href="https://bit.ly/betiptvgoogle">{word}</a>
              }
              return <span key={index}>{word} </span>;
            })}
            </p>
            {item.bulletPoints && item.bulletPoints.length > 0 ? (
            <ul className="bullet-points">
              {item.bulletPoints.map((bullet) => (
                <li style={{marginTop:'2%'}} key={bullet}>{bullet}</li>
              ))}
            </ul>
          ) : null}
          {item.contentII ? (
            <p className="news-content">
              {item.contentII.split(' ').map((word, index) => (
              <span key={index}>{word} </span>
              ))}
            </p>
          ) : null}
            <p className="news-date">{item.date}</p>
            <hr className="divider"/>
        </div>
      ))}
        </div>
    </body>
    );
};

export default News;