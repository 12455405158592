import React from "react";
import "./Home.css";
import mobile from "./mobile.png";
import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from 'react-i18next';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Home = () => {
    const { t } = useTranslation(["home", "common"]);
    useDocumentTitle('BET IPTV | ' + t("home:title"));
    
    return(
    <body className="container">
        <div className="pure">
            <p dangerouslySetInnerHTML={{__html: t("common:pure") }} />
            <p><span style={{fontWeight:"bold"}}>{t("home:noCH")} </span>{t("home:included")}</p>
        </div>
        <div className="title">
        </div>

        <div className="desc">
            <h3 className="detitle">{t("home:title1")}</h3>
            <p style={{color:'#000'}} dangerouslySetInnerHTML={{__html: t("home:text1") }} />
            <img src={mobile} className="mobile" alt="mobile"/>
            <p className='desct' style={{fontWeight:'bold', marginBottom:-50}}>{t("common:note")}</p>
            <br></br>
            <p className='desct' dangerouslySetInnerHTML={{__html: t("home:text2") }}/>
        </div>

        <div className="prtnrs">
        <AnimationOnScroll animateIn="animate__slideInUp" animateOnce >
            <h3 className='detitle'>{t("home:title2")}
            </h3>
        </AnimationOnScroll>
            <div className="brands">
            <AnimationOnScroll animateIn="animate__slideInUp" animateOnce >
                <p className="brandt">Samsung | TCL{/*LG */} |
                Sony | MI | Philips | Fire TV | Android TV | SHARP</p>
                </AnimationOnScroll>
            </div>
            <AnimationOnScroll animateIn="animate__zoomIn" animateOnce >

                <h2 className="detitle">{t("home:more")}</h2>
            </AnimationOnScroll>
        </div>

        <div className="dwnld">
            <AnimationOnScroll animateIn="animate__zoomIn" animateOnce>
            <h4 className="detitle">{t("home:title3")}</h4>
            </AnimationOnScroll>
            <div className="gplay">
            <a
            target="_blank" rel="noreferrer"
            href='https://play.google.com/store/apps/details?id=com.betstup.betiptv'>
                <img alt='Get it on Google Play' 
                    width={161.5}
                    height={62.5}
                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                </a>
            </div>
        </div>
    </body>
    );
};

export default Home;