import "./Download.css";
import aguy from "./aguy.png"
import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from 'react-i18next';

const Download = () => {
    
    const { t } = useTranslation(["download"]);
    useDocumentTitle('BET IPTV | ' + t("title"));

    return(
    <body className="dcontainer">
        <h1 style={{color: "black"}}>{t("title")}</h1>
        <p style={{color:"black",fontSize:"larger"}}>{t("text1")}</p>
        <div className="dbody">
            <div className="apk">
                <a href="https://bit.ly/betiptv2">
                <img src={aguy} alt="apk" className="apkguy" />
                <p>Android/APK</p>
                <p className="smallDesc">Version 2.0</p>
                </a>
            </div>
            <div className="dplay">
                <a href="https://play.google.com/store/apps/details?id=com.betstup.betiptv">
                <img src="https://cdn-icons-png.flaticon.com/512/732/732208.png" alt="gplay" className="dpico" />
                <p>Google Play</p>
                <p className="smallDesc">Version 2.0</p>
                </a>
            </div>
            <div className="dplay">
                <a href="#">
                <img src="https://seeklogo.com/images/S/samsung-smart-tv-logo-2392D2E765-seeklogo.com.png" alt="sam" className="smico" />
                <p>Samsung Smart TV</p>
                <p className="smallDesc">In Maintenance.</p>
                </a>
            </div>
        </div>
            <h3 className="more">{t("text2")}</h3>
        <div>
        </div>
    </body>
    );
};

export default Download;