import useDocumentTitle from "./useDocumentTitle";
import { Balancer } from "react-wrap-balancer";


export default function NotFound() {
    useDocumentTitle('BET IPTV | Cookies');
    return (
        <div style={{justifyContent:'center', alignItems:'center', display:'grid'}}>
            <h1>Cookies.</h1>
            <Balancer style={{textAlign:'justify',}}>We use cookies on our website to improve your experience and to analyze usage data. Cookies are small text files that are stored on your device when you visit our website. We use both session cookies (which expire when you close your browser) and persistent cookies (which stay on your device until they expire or you delete them). You can control the use of cookies through your browser settings. By continuing to use our website, you consent to the use of cookies in accordance with this policy.</Balancer>
        </div>
    )
}