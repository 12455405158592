import "./IDcheck.css";
import useDocumentTitle from "../../useDocumentTitle";
import React, { useState } from 'react';
import axios from 'axios';
import Reaptcha from 'reaptcha';
import { useTranslation } from 'react-i18next';

const IDcheck = () => {
    const [appID, setAppID] = useState('');
    const [verified, setVerified] = useState(false);

    const { t } = useTranslation(["appid"]);

    const onVrfyID = () => {
      setVerified(true);
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.get(`https://api-betiptv.onrender.com/api/checkAppID/${appID}`);
          // do something with the retrieved purl
          if(response.status === 200) {
            const res = await fetch(`https://api-betiptv.onrender.com/api/checkExpiration/${appID}`);
          const data = await res.json();
          const Status = new Date(data.status).toLocaleDateString();

          if(data.status === null) {
            alert(`Your Expiration date is: Unlimited`);
          } else {
            alert(`Your Expiration date is: ${Status}`);
          }
          } else {
            console.log("Something went wrong.");
          }
        } catch (error) {
          // handle error
          console.error(error);
          alert("Wrong AppID or Invalid.");
        }
    };      

    function handleInputChange(event) {
      const inputText = event.target.value;
      // Remove all non-alphanumeric characters from the input
      const cleanedText = inputText.replace(/[^a-zA-Z0-9]/g, "");
      // Add the ":" separator after every two letters
      const formattedText = cleanedText.replace(/(.{2})(?=.)/g, "$1:");
      setAppID(formattedText);
    }

    useDocumentTitle('BET IPTV | ' + t("appid:title"));

    return(
    <body className="icontainer">
        <div className="ibody">
            <h1 style={{color: "black",marginTop:"50px"}}>{t("appid:title")}</h1>
            <h4 style={{color: "black"}}>{t("appid:text")}</h4>
        </div>
        <div className="check">
                <h3 style={{color:"#DA213A"}}>{t("appid:check")}</h3>
                <form className="checkform" onSubmit={handleFormSubmit}>
                    <input 
                    type="text" 
                    autoComplete="off"
                    name="name" 
                    className="input"
                    maxLength={23}
                    placeholder="AppID"
                    value={appID} 
                    onChange={handleInputChange}
                     />
                    <Reaptcha className="recap" sitekey="6LfoBSAmAAAAAOiQOW3iY5MbTktQuJHgRJfzOACr" onVerify={onVrfyID} onExpire={() => setVerified(false)} onError={() => setVerified(false)} badge="inline"/> 
                    <button
                    className="submit"
                    disabled={!verified}>
                        {t("appid:check")}
                    </button>
                </form>
        </div>
        <p>
            ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍            ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍            ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍
            </p>
    </body>
    );
};

export default IDcheck;