import useDocumentTitle from "../../useDocumentTitle";
import React from 'react';
import Faq from 'react-faq-component';
import './Faq.css';
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";



export default function Faqs() {  
    const { t } = useTranslation(["faqs", "common", "header"]);
    useDocumentTitle('BET IPTV | ' + t("faqs:title"));

    const data = {
      rows: [
        {
          title: i18n.t("faqs:id1t"),
          content: i18n.t("faqs:id1c")
        },
        {
          title: i18n.t("faqs:id2t"),
          content: i18n.t("faqs:id2c")
        },
        {
          title: i18n.t("faqs:id3t"),
          content: i18n.t("faqs:id3c")
        },
        {
          title: i18n.t("faqs:id4t"),
          content: i18n.t("faqs:id4c")
        },
        {
          title: i18n.t("faqs:id5t"),
          content: i18n.t("faqs:id5c")
        },
        {
          title: i18n.t("faqs:id6t"),
          content: i18n.t("faqs:id6c")
        },
        {
          title: i18n.t("faqs:id7t"),
          content: i18n.t("faqs:id7c")
        },
        {
          title: i18n.t("faqs:id8t"),
          content: i18n.t("faqs:id8c")
        }
    ]
    };
    
    const styles = {
      // bgColor: 'black',
       rowTitleTextSize: 'large',
      // rowContentColor: 'grey',
      rowContentTextSize: 'medium',
      // rowContentPaddingTop: '10px',
    
      // rowContentPaddingRight: '150px',
      // arrowColor: "red",
      //transitionDuration: ".5s",
      // timingFunc: "ease"
    }





    return (
      <body className='fabody'>
        <h1 style={{color: 'black', marginTop:"40px"}}>{t("faqs:head")}</h1>
        <div className='facontainer'>
        <Faq 
        data={data}
        styles={styles}/>
        <h2 style={{color:'black', fontSize:'larger'}}>{t("faqs:text")}</h2>
        <p>
            ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍            ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
            </p>
        </div>
      </body>
    )
  }