//BET IPTV COM SOURCE CODE
//©BETSTUP UG. ALL RIGHTS RESERVED.
//CONFIDENTIAL - DO NOT SHARE THIS CODE!
import './App.css';
import CookieConsent from "react-cookie-consent";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Suspense } from 'react';

//Importing Pages
import News from "./pages/News/News";
import Upload from "./pages/Upload/Upload";
import Payment from "./pages/Payment/Payment";
import IDcheck from "./pages/IDcheck/IDcheck";
import Download from "./pages/Download/Download";
import Tutorial from "./pages/Tutorial/Tutorial";
import Faq from "./pages/Faq/Faqs";
import Contact from "./pages/Contact/Contact"
import Home from './pages/Home/Home';

//Privacy-Policies
import Privacy from './pages/Privacy/Privacy';
import Imprint from './pages/Privacy/Imprint';
import Terms from './pages/Terms/Terms';
import Refund from './pages/Refund/Refund';
import Cookies from './Cookies';

//404 Not found page
import NotFound from './NotFound';

//Success & Cancel pages
import Success from './Success';
import Cancel from './Cancel';

function App() {
  return (
  <>
  <Suspense fallback={null}>
    <div className="App">
    <Router>
      <Routes>
        <Route exact path ="/" element={<Home/>} />
        <Route path="/news" element={<News/>} />
        <Route path="/upload" element={<Upload/>} />         
        <Route path="/payment" element={<Payment/>} />
        <Route path="/appid" element={<IDcheck/>} />
        <Route path="/download" element={<Download/>} />
        <Route path="/tutorial" element={<Tutorial/>} />
        <Route path="/faq" element={<Faq/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/policy" element={<Privacy/>} />
        <Route path="/terms" element={<Terms/>} />
        <Route path="/refund" element={<Refund/>} />
        <Route path="/successful-payment" element={<Success/>} />
        <Route path="/cancel-payment" element={<Cancel/>} />
        <Route path="/cookies" element={<Cookies/>}/>
        <Route path="/imprint" element={<Imprint/>}/>
        <Route path='*' element={<NotFound />}/>
      </Routes>
    </Router>
    <CookieConsent 
    id="cookie-consent"
    enableDeclineButton 
    visible='byCookieValue'
    buttonText = 'Accept'
    declineButtonText = 'Decline'
    onAccept={() => localStorage.getItem("lng")}
    onDecline={() => localStorage.clear()}
    style={{
      background: "linear-gradient(100.81deg, #170218 -77.07%, #0B040B 100%)",
      textAlign: 'left',
      fontSize: 16
    }}
    buttonStyle={{
      background: "#304c89",
      color:'white',
    }}>
    This website uses cookies to enhance the user experience.
    </CookieConsent>
  </div>
  </Suspense>
  </>
  );
}

export default App;
