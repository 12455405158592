import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import useDocumentTitle from "../../useDocumentTitle";
import styled from "styled-components";
import "./Contact.css";
import axios from 'axios';
import { useTranslation } from 'react-i18next';


const Contact = () => {
  const { t } = useTranslation(["contact"]);
    useDocumentTitle('BET IPTV | ' + t("contact:title"));
    const [appID, setAppID] = useState('');

  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.get(`https://api-betiptv.onrender.com/api/checkAppID/${appID}`);
      // do something with the retrieved purl
      if(response.status === 200) {
        emailjs.sendForm('service_7o9q1tj', 'template_xjubtf7', form.current, '8DjIj6HkkCnRURBTI')
        .then((result) => {
            return;
        }, (error) => {
            alert(error.text);
        });

      alert(`Your message is sent to us. Thank you!`);
      } else {
        console.log("Something went wrong.");
      }
    } catch (error) {
      // handle error
      console.error(error);
      alert("Wrong AppID or Invalid.");
    }
  };

  function handleInputChange(event) {
    const inputText = event.target.value;
    // Remove all non-alphanumeric characters from the input
    const cleanedText = inputText.replace(/[^a-zA-Z0-9]/g, "");
    // Add the ":" separator after every two letters
    const formattedText = cleanedText.replace(/(.{2})(?=.)/g, "$1:");
    setAppID(formattedText);
  }

  return (
    <div className='Concontainer'>
    <StyledContactForm>
        <h1 style={{color:"black", marginTop:"50px"}}>{t("contact:title")}</h1>
    <form ref={form} onSubmit={sendEmail}>
      <label>AppID</label>
      <input type="text" name="user_name" required onChange={handleInputChange} maxLength={23} />
      <label>Email</label>
      <input type="email" name="user_email" required />
      <label>{t("contact:msg")}</label>
      <textarea name="message" />
      <input type="submit" value={t("contact:send")} />
    </form>
    </StyledContactForm>
    </div>
  );
};

export default Contact;

// Styles
const StyledContactForm = styled.div`
  width: 400px;
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-size: 16px;
    input {
      width: 100%;
      height: 35px;
      font-size: medium;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      font-size: medium;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    label {
      margin-top: 1rem;
    }
    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: linear-gradient(100.81deg, #283666 -77.07%, #101837 100%);
      color: white;
      border: none;
      width: 415px;
    }
    @media screen and (max-width: 560px) {
      width: 200px;
      input[type="submit"] {
        width: 215px;
      }
      position: relative;
      left: 100px
  }
  }
`;