import { Link } from "react-router-dom";

export default function NotFound() {
    return (
        <div>
            <h1>Oops! You seem to be lost.</h1>
            <p>Here are some helpful links:</p>
            <Link style={{color:"white"}} to='/'>Home</Link>
            <Link style={{color:"white"}} to='/news'>News</Link>
            <Link style={{color:"white"}} to='/payment'>Payment</Link>
            <Link style={{color:"white"}} to='/contact'>Contact</Link>
        </div>
    )
}