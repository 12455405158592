import "./Tutorial.css";
import useDocumentTitle from "../../useDocumentTitle";
import { useTranslation } from 'react-i18next';

const Tutorial = () => {
    const { t } = useTranslation(["tutorial"]);
    useDocumentTitle('BET IPTV | ' + t("tutorial:title"));

    return(
    <body className="Tucontainer">
        <div className="Tubody">
            <h1 style={{color: "black",marginBottom:"15%"}}>{t("tutorial:title")}</h1>
            <p style={{color: "black",marginTop:"15% auto",width:"97%",alignItems:'center',justifyContent:'center',margin:'0 auto'}}>{t("tutorial:text")}</p>
        </div>
        <div>
            <p>Test call</p>
        </div>
    </body>
    );
};

export default Tutorial;