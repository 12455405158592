import "./Upload.css";
import useDocumentTitle from "../../useDocumentTitle";
import React, { useState } from 'react';
import axios from 'axios';
import Reaptcha from 'reaptcha';
import { useTranslation } from 'react-i18next';

const Upload = () => {
  const { t } = useTranslation(["upload"]);
  useDocumentTitle('BET IPTV | ' + t("title"));
    const [appIdToDelete, setAppIdToDelete] = useState('');
    const [appID, setAppID] = useState('');
    const [pURL, setPURL] = useState('');
    const [verified, setVerified] = useState(false);
    const [vrfyDel, setVrfyD] = useState(false);
    const onVrfy = () => {setVerified(true);};
    const onVrfyDEL = () => {setVrfyD(true);};
  
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.get(`https://api-betiptv.onrender.com/api/checkAccess/${appID}`);
          // do something with the retrieved purl
          if(response.status === 200) {
            await axios.post(`https://api-betiptv.onrender.com/api/postPURL/${appID}`, { pURL: pURL });
            alert("Playlist URL uploaded! Please Restart the BET-IPTV App.");
          } else {
            console.log("Something went wrong.");
          }
        } catch (error) {
          // handle error
          console.error(error);
          alert("Wrong AppID or Invalid. Please check if you have paid on ID Check.");
        }
      };
      
      

    const handleDeleteAppId = async (e) => {
        e.preventDefault();
      try {
        const response = await axios.get(`https://api-betiptv.onrender.com/api/checkAppID/${appIdToDelete}`);
        if(response.status === 200) {
            await axios.delete(`https://api-betiptv.onrender.com/api/appids/${appIdToDelete}`);
            alert("Playlist deleted successfully.");
          } else {
            alert("Something went wrong. Please Contact Support.")
          }
      } catch (error) {
        console.error(error); // handle error if needed
        alert("AppID either could not be deleted or does not exist.")
        
      }
    };

    function handleInputChange(event) {
      const inputText = event.target.value;
      // Remove all non-alphanumeric characters from the input
      const cleanedText = inputText.replace(/[^a-zA-Z0-9]/g, "");
      // Add the ":" separator after every two letters
      const formattedText = cleanedText.replace(/(.{2})(?=.)/g, "$1:");
      setAppID(formattedText);
    }

    function handleInputChangeDEL(event) {
      const inputText = event.target.value;
      // Remove all non-alphanumeric characters from the input
      const cleanedText = inputText.replace(/[^a-zA-Z0-9]/g, "");
      // Add the ":" separator after every two letters
      const formattedText = cleanedText.replace(/(.{2})(?=.)/g, "$1:");
      setAppIdToDelete(formattedText);
    }
    
    return(
    <body className="ucontainer">
        <div className="ubody">
            <h1 style={{color: "black",marginTop:"60px"}}>{t("upload:title")}</h1>
            <h3 style={{color: "black"}}>{t("upload:text1")}</h3>
            <p style={{color: "black"}}>{t("upload:text2.1")} <span style={{fontWeight:"bold"}}>UTF-8 (NO BOM)</span> {t("upload:text2.2")}</p>
        </div>
        <div className="rowCon">
            <div className="login">
                <h3 style={{color:"#DA213A"}}>{t("upload:upload")}</h3>
                <form className="form" onSubmit={handleFormSubmit}>
                    <input 
                    type="text" 
                    autoComplete="off"
                    name="appid" 
                    className="uinput"
                    maxLength={23}
                    placeholder="AppID"
                    value={appID} 
                    onChange={handleInputChange}
                     />
                    <input 
                    type="text" 
                    autoComplete="off"
                    name="name" 
                    className="uinput"
                    placeholder="Playlist URL"
                    value={pURL} 
                    onChange={(event) => setPURL(event.target.value)}
                     />
                    <Reaptcha className="recap" sitekey="6LfoBSAmAAAAAOiQOW3iY5MbTktQuJHgRJfzOACr" onVerify={onVrfy} onExpire={() => setVerified(false)} onError={() => setVerified(false)} badge="inline"/>
                    <button
                    className="submit"
                    disabled={!verified}>
                        {t("upload:addList")}
                    </button>
                </form>
              </div>
            <div className="delete">
                <h3 style={{color:"#DA213A"}}>{t("upload:delete")}</h3>
                <form className="form delete" onSubmit={handleDeleteAppId}>
                    <input 
                    type="text" 
                    autoComplete="off"
                    name="name" 
                    className="uinput"
                    maxLength={23}
                    placeholder="AppID"
                    value={appIdToDelete}
                    onChange={handleInputChangeDEL}
                     />
                     <Reaptcha className="recap" sitekey="6LfoBSAmAAAAAOiQOW3iY5MbTktQuJHgRJfzOACr" onVerify={onVrfyDEL} onExpire={() => setVrfyD(false)} onError={() => setVrfyD(false)} badge="inline"/>
                    <button
                    className="submit"
                    disabled={!vrfyDel}>
                        {t("upload:deleteList")}
                    </button>
                </form>
            </div>
        </div>
            <p>
            ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍            ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍            ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ 
‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍ ‍
            </p>
    </body>
    );
};

export default Upload;