import { Link } from "react-router-dom";
import './Success.css';
import { useTranslation } from 'react-i18next';

const Success = () => {
    const { t } = useTranslation(["common"]);
    return (
      <div>
        <h1 style={{color:'#29e795'}}>{t("success")}</h1>
        <h2><span style={{color:'#6d9dc5'}}>{t("thankyou")}</span>{t("purchase")}</h2>
        <p className="text">{t("uploadList")} <Link className="sutext" to='/upload'>{t("uploads")}</Link></p>
      </div>
    );
  };
  
  export default Success;