import "./Payment.css";
import useDocumentTitle from "../../useDocumentTitle";
import React, { useState } from "react";
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Balancer } from "react-wrap-balancer";
import Reaptcha from 'reaptcha';
import { useTranslation } from 'react-i18next';

const Payment = () => {
    const stripePromise = loadStripe('pk_live_51N19YALqsDJ0S3Kkn4n1BFTDVvwpH6NeLkP6yQOiOuVhqcwNi6qGaD6OlPo4PV0Tta62C5ZADSER1PoO92RdP8Gq000kWAu6oF');
    const [appID, setAppID] = useState('');
    const [email, setEmail] = useState('');
    const [verified, setVerified] = useState(false);
    const [vryfiplan, setVryfp] = useState(false);
    const [vryLTD, setLTD] = useState(false);
    const onVrfy = () => {setVerified(true);};
    const onVrfyP = () => {setVryfp(true);};
    const onVrfyLTD = () => {setLTD(true);};

    const { t } = useTranslation(["payment", "common", "footer"]);
    useDocumentTitle('BET IPTV | ' + t("payment:title"));
    
    const handleCheckout = async (priceId, email, appID, planType) => {

      try {
      // Check the AppID from the backend first
      const check = await axios.get(`https://api-betiptv.onrender.com/api/checkAppID/${appID}`);
        if (check.status === 200) {
          const response = await axios.post('https://api-betiptv.onrender.com/api/create-checkout-session', { priceId, email, appID, planType });
          const sessionId = response.data.id;
          const stripe = await stripePromise;
          const result = await stripe.redirectToCheckout({ sessionId });
            if (result.error) {
              console.error(result.error.message);
          }
        } else {
          // Show an error message to the user
          console.log('AppID does not exist in database');
          }
      } catch (error) {
        // Show an error message to the user
        alert("Wrong AppID or Invalid. Please make sure you started the App once.");
        }
      };

    const handleButton1Click = (e) => {
      e.preventDefault();
      handleCheckout('price_1PO4WCLqsDJ0S3KkaqpH0odY', email, appID, 'unlimited'); 
    };
  
    const handleButton2Click = (e) => {
      e.preventDefault();
      handleCheckout('price_1PO4Y5LqsDJ0S3Kkid4lhYLL', email, appID, 'planned'); 
    };

    function handleInputChange(event) {
      const inputText = event.target.value;
      // Remove all non-alphanumeric characters from the input
      const cleanedText = inputText.replace(/[^a-zA-Z0-9]/g, "");
      // Add the ":" separator after every two letters
      const formattedText = cleanedText.replace(/(.{2})(?=.)/g, "$1:");
      setAppID(formattedText);
    }

    return(
    <body className="pcontainer">
            <h1 style={{color: "black"}}>{t("payment:title")}</h1>
        <div className="pbody">
            <Balancer className='desct pay'>
            <p style={{fontWeight:'bold'}}>{t("common:note")}</p>
            <p dangerouslySetInnerHTML={{__html: t("payment:text1") }} />
            </Balancer>
            <h3 style={{color:"black"}}>{t("payment:method")}</h3>
            <h3 className="blinkT" style={{color:"#FF0000"}}>{t("payment:limited")}</h3>
        </div>
        <div className="rowCon pay">
            <div className="unlimited">
                <form className="form pay" onSubmit={handleButton1Click}>
                <h3 style={{fontSize: 'large', color:"#47c852"}}>{t("payment:unlimited")}</h3>
                <h4 style={{fontSize: 'large', color:"black", marginTop: -5}}>{t("payment:recommendation")}</h4>
                <p style={{fontSize: 'large', color:"#d62828", fontWeight:"bold", textDecoration:'line-through'}}>10.99€</p>
                <p style={{fontSize: 'large', color:"black", fontWeight:"bold"}}>9.99€</p>
                    <input 
                    type="email" 
                    autoComplete="off"
                    name="email" 
                    className="input"
                    required
                    placeholder="Email"
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)}
                     />
                    <input 
                    type="text" 
                    autoComplete="off"
                    name="name" 
                    className="input"
                    maxLength={23}
                    placeholder="AppID"
                    required
                    value={appID} 
                    onChange={handleInputChange}
                     />
                     <Reaptcha className="recap" sitekey="6LfoBSAmAAAAAOiQOW3iY5MbTktQuJHgRJfzOACr" onVerify={onVrfy} onExpire={() => setVerified(false)} onError={() => setVerified(false)} badge="inline"/>
                    <button
                    className="submit pay"
                    disabled={!verified}>
                    {t("payment:payButton")}
                    </button>
                    <p style={{color:"black",fontSize:"small"}}>{t("payment:agree")} <a className="apay" href="/terms">{t("footer:terms")}</a> {t("common:and")} <a className="apay" href="/refund">{t("footer:refund")}</a>{t("agree1.1")}</p>
                </form>
            </div>
            <div className="sub">
                <form className="form pay" onSubmit={handleButton2Click}>
                <h3 style={{fontSize: 'large', color:"#2C245C"}}>{t("payment:24Months")}</h3>
                <p style={{fontSize: 'large', color:"#d62828", fontWeight:"bold", textDecoration:'line-through'}}>4.99€</p>
                <p style={{fontSize: 'large', color:"black", fontWeight:"bold"}}>3.99€</p>
                    <input 
                    type="email" 
                    autoComplete="off"
                    name="email" 
                    className="input"
                    placeholder="Email"
                    required
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)}
                     />
                    <input 
                    type="text" 
                    autoComplete="off"
                    name="name" 
                    className="input"
                    maxLength={23}
                    placeholder="AppID"
                    value={appID} 
                    onChange={handleInputChange}
                    required
                     />
                     <Reaptcha className="recap" sitekey="6LfoBSAmAAAAAOiQOW3iY5MbTktQuJHgRJfzOACr" onVerify={onVrfyP} onExpire={() => setVryfp(false)} onError={() => setVryfp(false)} badge="inline"/>
                    <button
                    className="submit pay"
                    disabled={!vryfiplan}>
                    {t("payButton")}
                    </button>
                    <p style={{color:"black",fontSize:"small"}}>{t("payment:agree")} <a className="apay" href="/terms">{t("footer:terms")}</a> {t("common:and")} <a className="apay" href="/refund">{t("footer:refund")}</a>{t("payment:agree1.1")}</p>
                </form>
            </div>
        </div>
        <img alt="stripe" width = "250px" src="https://www.logo.wine/a/logo/Stripe_(company)/Stripe_(company)-Powered-by-Stripe-Outline-Logo.wine.svg" />
    </body>
    );
};

export default Payment;