import React, { useEffect } from "react";
import logo from "./logo.png";
import "./Header.css";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";

function Header() {
  const [isNavExpanded, setIsNavExpanded] = React.useState(false);
  const { i18n, t } = useTranslation(["header"]);

  useEffect(() => {
    if(localStorage.getItem("lng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  const handleChangeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("lng", e.target.value);
    window.location.reload();
  }

  useEffect(() => {
    console.log(localStorage.getItem("lng"));
  }, []);

  return (
    <header>
      <nav className="btcon">
      <a href="/"><img src={logo} className="logo" alt="logo"/></a>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
        <div className="nav-menu">
        <ul>
          <li><a href="/news">{t("news")}</a></li>
          <li><a href="/upload">{t("upload")}</a></li>
          <li><a href="/payment">{t("payment")}</a></li>
          <li><a href="/appid">{t("appid")}</a></li>
          <li><a href="/download">{t("download")}</a></li>
          <li><a href="/tutorial">{t("tutorial")}</a></li>
          <li><a href="/faq">{t("faq")}</a></li>
          <button className="contact"><a className="context" href="/contact">{t("contact")}</a></button>
          <div class="language-dropdown">
          <select defaultValue={i18n.resolvedLanguage} value={i18n.resolvedLanguage} onChange={handleChangeLanguage}>
                  <option value="en">🇬🇧</option>
                  <option value="de">🇩🇪</option>
                  <option value="es">🇪🇸</option>
                  <option value="fr">🇫🇷</option>
                  <option value="tr">🇹🇷</option>
                  <option value="ar">🇸🇦</option>
              </select>
          </div>
        </ul>
        </div>
      {isNavExpanded ? (
      <div className="nav-expanded">
        <ul>
          <li><a href="/news">{t("news")}</a></li>
          <li><a href="/upload">{t("upload")}</a></li>
          <li><a href="/payment">{t("payment")}</a></li>
          <li><a href="/appid">{t("appid")}</a></li>
          <li><a href="/download">{t("download")}</a></li>
          <li><a href="/tutorial">{t("tutorial")}</a></li>
          <li><a href="/faq">{t("faq")}</a></li>
          <button className="contact expanded"><a className="context" href="/contact">{t("contact")}</a></button>
          <div className="language-dropdown">
          <select defaultValue={i18n.resolvedLanguage} value={i18n.resolvedLanguage} onChange={handleChangeLanguage}>
                  <option value="en">🇬🇧</option>
                  <option value="de">🇩🇪</option>
                  <option value="es">🇪🇸</option>
                  <option value="fr">🇫🇷</option>
                  <option value="tr">🇹🇷</option>
                  <option value="ar">🇸🇦</option>
              </select>
          </div>
        </ul>
      </div>) : (<></>)

      }
      </nav>
    </header>
  );
}

export default Header;
