import { useTranslation } from 'react-i18next';
const Cancel = () => {
    const { t } = useTranslation(["common"]);
    return (
      <div>
        <h1>{t("canceled")}.</h1>
        <h2>{t("canceledText")}.</h2>
      </div>
    );
  };
  
  export default Cancel;
  