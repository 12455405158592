import "./Footer.css";
import infos from "./pages/Home/info.png";
import { resetCookieConsentValue } from "react-cookie-consent";
import { useTranslation } from 'react-i18next';

const Footer = () => {

    const cookies = () => {
        resetCookieConsentValue();
    }

    const getYear = () => {
        return new Date().getFullYear();
    }

    const { t } = useTranslation(["footer"]);

    return(
    <footer className="foot">

        <div className="infos">
            <a className="infg" href="/">
            <img src={infos} className="infg" alt="betiptv"/>
            </a>
            <p className="intext">{t("contactUs")} <br></br>
            <a href="mailto:support@betstup.com" style={{color:"white"}}>
                <span style={{fontWeight:"bold"}}>support@betiptv.com</span>
            </a>
            </p>
            <div className="intc">
            <p className="intext">{t("infoFooter1")}  
            <span style={{fontWeight:"bold"}}><a href="/upload" style={{color:'white'}}>{t("UploadsFooter")} </a></span>
            {t("infoFooter2")}</p>
            </div>
        </div>

        <div className="footer">
            <p style={{marginRight:'50px'}}>©{getYear()} <span><a style={{color:'white', fontSize:'medium'}} href="https://betiptv.com">BETIPTV{/*BetStup UG*/}</a></span>. {t("AllRights")}</p>
            <a href="/policy" className="fotext">{t("policy")}</a>
            <a href="/terms" className="fotext">{t("terms")}</a>
            <a href="/cookies" onClick={cookies} className="fotext">{t("cookies")}</a>
            <a href="/refund" className="fotext">{t("refund")}</a>
            <a href="/imprint" className="fotext">{t("Imprint")}</a>
        </div>
    </footer>
    );
};

export default Footer;